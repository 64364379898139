import React, { useEffect } from "react";
import Navbar from "../../components/Navbar/Navbar";
import InputField from "../../components/InputField";
import Button from "../../components/ButtonField";
import "./Withdrawal.scss";
import { HiArrowsRightLeft } from "react-icons/hi2";
import { InputNumber, Select, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  Wallatedata,
  getdappWallatedata,
  getdappWallatedata1,
} from "../../Redux/WallatedatSlice";
import { Transferdata } from "../../Redux/TranfarSlice";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Web3 from "web3";
import CreatableSelect from "react-select/creatable";
import { apiList } from "../../Redux/api";
import axios from "axios";
import Navbar1 from "../../components/Navbar/Navbar";
function Withdrawal() {
  const navigation = useNavigate();
  const WallatedatSlice = useSelector((state) => state.WallatedatSlice);
  const [Alldata, setAlldata] = React.useState([]);
  const [Profile, setProfile] = React.useState({});
  const [Wallet, setWallet] = React.useState("");
  const [Wallet1, setWallet1] = React.useState("");
  const [open, setopen] = React.useState(false);
  const [open1, setopen1] = React.useState(false);
  const [otp, setotp] = React.useState("");
  const [address, setaddress] = React.useState("");
  const [WalletAmountcal, setWalletAmountcal] = React.useState(0);
  const [WalletAmountcal1, setWalletAmountcal1] = React.useState(0);
  const [WalletAmountcal3, setWalletAmountcal3] = React.useState(0);
  const [adde, setadde] = React.useState("");
  const handleClose = () => setopen(!false);
  let refurserdata =
    WallatedatSlice.Wallatedata?.data &&
    WallatedatSlice.Wallatedata?.data?.ReffData1.map((e, i) => {
      return {
        value: e._id,
        label: e.username,
      };
    });
  const [Username1, setUsername1] = React.useState("");
  const [Username, setUsername] = React.useState("");
  const [values, setValues] = React.useState({
    Amount: "",
    Amount1: "",
  });
  const [validations, setValidations] = React.useState({
    Amount: "",
    username: "",
  });
  const { Amount, Amount1, username } = values;
  const {
    Amount: AmountVal,
    Amount1: Amount1val,
    username: UsernameVal,
  } = validations;
  const validateOne = (e) => {
    const { name } = e.target;
    const value = values[name];
    let message = "";
    if (!value) {
      if (name !== "Emailforgot") {
        message = `${
          name === "Reenterpassword" ? "Confirm Password" : name
        } is required!`;
      } else {
        message = `Email is required!`;
      }
    }
    setValidations({ ...validations, [name]: message });
  };
  const dispatch = useDispatch();
  useEffect(() => {
    getalldata();
    getalldata1();
  }, []);
  const calculateSums = (data) => {
    let sumActive = 0;
    let sumInactive = 0;

    data?.forEach((deposit) => {
      if (deposit.active === 1) {
        sumActive += deposit.amount;
      } else if (deposit.active === 0) {
        sumInactive += deposit.amount;
      }
    });

    return { sumActive, sumInactive };
  };
  const sums = calculateSums(Alldata);
  useEffect(() => {
    let b = WalletAmountcal;
    let a = WalletAmountcal1;
    console.log("bbb", b);
    console.log("bbb", a);
    setWallet(a - b);
  }, [Alldata]);
  const getalldata = async () => {
    try {
      let headersList = {
        Accept: "*/*",
        "User-Agent": "Thunder Client (https://www.thunderclient.com)",
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("data")) &&
          JSON.parse(localStorage.getItem("data")).data.token
        }`,
        "Content-Type": "application/json",
      };
      // https://infinityai-backend-production.up.railway.app/api/depositdata/deposits
      let reqOptions = {
        url: "https://infinityai-backend-production.up.railway.app/api/depositdata/deposit",
        method: "GET",
        headers: headersList,
      };

      let response = await axios.request(reqOptions);
      setAlldata(response.data.deposit);
      console.log("response.data", response.data.deposit);
      const sums = calculateSums(response.data.deposit);
      console.log(
        "sums.sumActive - sums.sumInactiv;;;;e",
        sums
      );
      setWalletAmountcal1(sums.sumActive);
    } catch (error) {}
    // const res = await dispatch(
    //   Wallatedata({
    //     Token:
    //       JSON.parse(localStorage.getItem("data")) &&
    //       JSON.parse(localStorage.getItem("data")).data.token,
    //   })
    // );
    // setAlldata(res.payload.data.data);
    // setWalletAmountcal(res.payload.data.data[0]?.mainWallet);
    // setProfile(res.payload.data.profile);
  };
  const getalldata1 = async () => {
    try {
      let headersList = {
        Accept: "*/*",
        "User-Agent": "Thunder Client (https://www.thunderclient.com)",
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("data")) &&
          JSON.parse(localStorage.getItem("data")).data.token
        }`,
        "Content-Type": "application/json",
      };
      // https://infinityai-backend-production.up.railway.app/api/depositdata/deposits
      let reqOptions = {
        url: "https://infinityai-backend-production.up.railway.app/api/Withdraw/withdraw",
        method: "GET",
        headers: headersList,
      };

      let response = await axios.request(reqOptions);
      setAlldata(response.data.deposit);
      console.log("sumssumssumssums", response.data.deposit);
      const sums = calculateSums(response.data.deposit);
      console.log("sumssumssumssums", sums);
      console.log("sumssumssumssums", sums.sumInactive + sums.sumActive);
      setWalletAmountcal(sums.sumInactive + sums.sumActive);
      setWalletAmountcal3(sums.sumInactive);
    } catch (error) {}
    // const res = await dispatch(
    //   Wallatedata({
    //     Token:
    //       JSON.parse(localStorage.getItem("data")) &&
    //       JSON.parse(localStorage.getItem("data")).data.token,
    //   })
    // );
    // setAlldata(res.payload.data.data);
    // setWalletAmountcal(res.payload.data.data[0]?.mainWallet);
    // setProfile(res.payload.data.profile);
  };
  const onChange = (value) => {
    setWallet1(value);
  };
  const onChange1 = (e) => {
    console.log("console.log()", e.target.value);
    setadde(e.target.value);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };
  const validateAll = () => {
    const { Amount, Amount1, username } = values;
    const validations = {
      Amount: "",
      Amount1: "",
      username: "",
    };
    let isValid = true;

    if (!Amount) {
      validations.Amount = "Amount is required!";
      isValid = false;
    }
    if (!Amount1) {
      validations.Amount1 = "Amount is required!";
      isValid = false;
    } else if (Amount1 > 50) {
      validations.Amount1 = "Amount is not valid required!";
    }
    if (!username) {
      validations.username = "Username is required!";
      isValid = false;
    }
    if (!isValid) {
      setValidations(validations);
    }
    return validations;
  };

  const handleSubmit = async (e) => {
    console.log(validateAll());
    if (validateAll().Amount === "" && validateAll().username === "") {
      setopen1(!open1);
      setotp("");
      let headersList = {
        Accept: "*/*",
        Authorization: `${
          JSON.parse(localStorage.getItem("data")) &&
          JSON.parse(localStorage.getItem("data")).data.token
        }`,
      };

      let reqOptions = {
        url: apiList.tranferotpsend,
        method: "GET",
        headers: headersList,
      };

      let response = await axios.request(reqOptions);
      // const res = await dispatch(
      //   tranferotpsend({
      //     Token:
      //       JSON.parse(localStorage.getItem("data")) &&
      //       JSON.parse(localStorage.getItem("data")).data
      //         .token,
      //   })
      // );
      // if (res.payload.data.isSuccess) {
      //   setotp("");
      //   toast.success(res.payload.data.message);
      //   setopen1(!open1);
      // } else {
      //   toast.error(res.payload.data.message);
    }
  };

  return (
    <>
      <Spin spinning={!WallatedatSlice?.isLoader}>
        <>
          <Navbar1 d={false} />

          <div className="container-fluid mt-5 pt-5">
            <div className="">
              <button
                className="btn btn-primary mx-3 mt-3"
                onClick={() => {
                  navigation("/dashboard");
                }}
              >
                back to home
              </button>
              <div className="row px-3 pt-4">
                <div className="col-12 pb-4 pb-md-0 ">
                  <div className="Boxcard p-4">
                    <h6 className="text-light d-flex ">
                      Withdraw USDT
                      <img
                        src={require("../../assets/img/Vector (28).png")}
                        alt=""
                        className="img-fluid mx-3"
                      />{" "}
                    </h6>
                    <div className="Withdrawalbox px-4 py-4 mt-4">
                      <div className="d-lg-flex justify-content-around">
                        <div className="px-3 py-2 py-lg-0">
                          <h6 className="m-0 text-light text-center">
                            Main Wallet Balance
                          </h6>
                        </div>
                        <div className="px-3 py-2 py-lg-0">
                          <h6 className="m-0 text-light text-center">
                            {Wallet} USDT
                          </h6>
                        </div>
                      </div>
                    </div>
                    <div className="row py-2">
                      <div className="col-12 col-sm-9 text-light">
                        <label htmlFor="" className="mb-1 text-light">
                          <b>Amount in USDT</b>
                        </label>
                        <InputNumber
                          size="large"
                          className="w-100 "
                          onChange={onChange}
                          style={{ padding: "10px 0px" }}
                        />
                      </div>
                      <div className="col-12 col-sm-9 text-light">
                        <label htmlFor="" className="mb-1 text-light">
                          <b>TRC 20 Wallet Address</b>
                        </label>
                        {/* <InputNumber
                          size="large"
                          className="w-100 "
                          onChange={onChange1}
                          style={{ padding: "10px 0px" }}
                        /> */}
                        <InputField
                          type="text"
                          name="Amount1"
                          value={adde}
                          placeholder="TRC 20 Wallet Address"
                          onChange={onChange1}
                          style={{ border: "1px solid #fff" }}
                        />
                      </div>
                      <div className="col-12 col-sm-3 text-light">
                        <Button
                          className={" w-100 text-light"}
                          Stake={!false}
                          style={{
                            background: "#0c7f52",
                            height: 65,
                            border: "none",
                          }}
                          label={"Withdraw "}
                          onClick={async () => {
                            console.log("console.log()", WalletAmountcal1);
                            console.log("console.log()", WalletAmountcal);
                            if (WalletAmountcal1 - WalletAmountcal > 0) {
                              if (Wallet <= Wallet) {
                                let headersList = {
                                  Accept: "*/*",
                                  "User-Agent":
                                    "Thunder Client (https://www.thunderclient.com)",
                                  Authorization: `Bearer ${
                                    JSON.parse(localStorage.getItem("data")) &&
                                    JSON.parse(localStorage.getItem("data"))
                                      .data.token
                                  }`,
                                  "Content-Type": "application/json",
                                };
                                let bodyContent = JSON.stringify({
                                  amount: Wallet1,
                                  address: adde,
                                  active: true,
                                });

                                let reqOptions = {
                                  url: "https://infinityai-backend-production.up.railway.app/api/Withdraw/withdraw",
                                  method: "POST",
                                  headers: headersList,
                                  data: bodyContent,
                                };

                                let response = await axios.request(reqOptions);
                                console.log(response.data);
                                toast.success("withdraw request send !");
                                getalldata();
                              } else {
                                toast.error(
                                  "oops something went wrong please try again !"
                                );
                              }
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="Boxcard p-4 my-4">
                    <h6 className="text-light d-flex pb-3">Withdraw USDT</h6>
                    <Button
                      className={" w-100 text-light"}
                      Stake={!false}
                      style={{
                        background: "#0c7f52",
                        height: 60,
                        border: "none",
                      }}
                      onClick={() => {
                        navigation("/Withdrdata");
                      }}
                      label={"Withdrawal History"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
        <Modal show={open1} centered>
          <Modal.Header>
            <Modal.Title>
              <h6 className="text-light m-0"></h6>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <InputField
              type="number"
              name="Amount1"
              value={otp}
              placeholder="Enter Your OTP"
              pattern="[0-9]*"
              onChange={(e) => {
                setotp(e.target.value);
              }}
              style={{ border: "1px solid #fff" }}
              onBlur={validateOne}
            />
            <Button
              className={" w-100 text-light"}
              Stake={!false}
              style={{
                background: "#1a1a1a",
                height: 60,
                border: "none",
              }}
              label={"Submit"}
              onClick={async () => {
                const res = await dispatch(
                  Transferdata({
                    Username: username,
                    otp: otp,
                    Wallet: Wallet,
                    Amount: Amount,
                    Token:
                      JSON.parse(localStorage.getItem("data")) &&
                      JSON.parse(localStorage.getItem("data")).data.token,
                  })
                );
                if (res.payload.data.isSuccess) {
                  setopen1(!open1);
                  toast.success(res.payload.data.message);
                  getalldata();
                } else {
                  toast.error(res.payload.data.message);
                }
              }}
            />
          </Modal.Body>
        </Modal>
        <Modal show={open} centered>
          <Modal.Header>
            <Modal.Title>
              <h6 className="text-light m-0"></h6>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <InputField
              type="number"
              name="Amount1"
              value={otp}
              placeholder="Enter Your OTP"
              pattern="[0-9]*"
              onChange={(e) => {
                setotp(e.target.value);
              }}
              style={{ border: "1px solid #fff" }}
              onBlur={validateOne}
            />
            <InputField
              type="text"
              name="Amount1"
              value={address}
              placeholder="Enter Your Wallate Address"
              pattern="[0-9]*"
              onChange={(e) => {
                setaddress(e.target.value);
              }}
              style={{ border: "1px solid #fff" }}
              onBlur={validateOne}
            />
            <Button
              className={" w-100 text-light"}
              Stake={!false}
              style={{
                background: "#1a1a1a",
                height: 60,
                border: "none",
              }}
              label={"Submit"}
              onClick={async () => {
                setopen(!open);
                const res = await dispatch(
                  getdappWallatedata1({
                    otp: otp,
                    walletaddress: address,
                    Amount: WalletAmountcal,
                    Remark: "main wallate",
                    Token:
                      JSON.parse(localStorage.getItem("data")) &&
                      JSON.parse(localStorage.getItem("data")).data.token,
                  })
                );
                if (res.payload.data.isSuccess) {
                  getalldata();
                  toast.success(res.payload.data.message);
                } else {
                  toast.error(res.payload.data.message);
                }
              }}
            />
          </Modal.Body>
        </Modal>
      </Spin>
    </>
  );
}

export default Withdrawal;
