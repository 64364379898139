import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Navbaradmin from "../../components/Navbaradmin/Navbaradmin";
import { Stackingbouns } from "../../Redux/Stackingbouns";
import { Button, Modal, Select, Spin, Table, Tooltip } from "antd";
import { getTransferdata } from "../../Redux/TranfarSlice";
import {
  Adminprice,
  Adminuserdata,
  Alltranfordata,
  userdatablock,
  AdminsendAmount,
  Admintranfor,
  Withdrdatadata,
  Withdrdatadata12,
  Withdrdatadatauser,
} from "../../Redux/admin";
import InputField from "../../components/InputField";
import { toast } from "react-toastify";
import Navbar1 from "../../components/Navbar/Navbar";
import axios from "axios";

function Withdrdatadata1() {
  const StackingbounsSlice = useSelector((state) => state.Adminuserdata);
  const [page, setpage] = React.useState(1);
  const [pageSize, setpageSize] = React.useState(10);
  const [Alldata, setAlldata] = React.useState([]);
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const location = useLocation();
  const [values, setValues] = React.useState({
    username: "",
    Amount: "",
    Walletname: "Main Wallet",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };
  useEffect(() => {
    getalldata2();
  }, []);

  const getalldata2 = async () => {
    try {
      let headersList = {
        "Accept": "*/*",
        "User-Agent": "Thunder Client (https://www.thunderclient.com)",
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("data")) &&
          JSON.parse(localStorage.getItem("data")).data.token
          }`, "Content-Type": "application/json"
      }
      // https://infinityai-backend-production.up.railway.app/api/depositdata/deposits
      let reqOptions = {
        url: "https://infinityai-backend-production.up.railway.app/api/Withdraw/withdraw",
        method: "GET",
        headers: headersList,
      }

      let response = await axios.request(reqOptions);
      setAlldata(response.data.deposit);
    } catch (error) { }
    // const res = await dispatch(
    //   Wallatedata({
    //     Token:
    //       JSON.parse(localStorage.getItem("data")) &&
    //       JSON.parse(localStorage.getItem("data")).data.token,
    //   })
    // );
    // setAlldata(res.payload.data.data);
    // setWalletAmountcal(res.payload.data.data[0]?.mainWallet);
    // setProfile(res.payload.data.profile);
  };
  const handleSubmit = async (e) => {
    if (values.username !== "" || values.Amount > 0) {
      const res = await dispatch(
        Withdrdatadata({
          Token:
            JSON.parse(localStorage.getItem("data")) &&
            JSON.parse(localStorage.getItem("data")).data.token,
        })
      );
      if (res.payload.data.isSuccess) {
        toast.success(res.payload.data.message);
      } else {
        toast.error(res.payload.data.message);
      }
    }
  };
  const columns = [
    {
      title: "Sr No",
      dataIndex: "sno",
      key: "sno",
      width: "80px",
      render: (value, item, index) =>
        page === 1 ? index + 1 : (page - 1) * pageSize + (index + 1),
    },

    {
      title: "amount",
      dataIndex: "amount",
      key: "amount",
      ellipsis: {
        showTitle: false,
      },
      width: "400px",
      render: (text, record, index) => (
        <Tooltip placement="topLeft" title={record?.amount}>
          {record?.amount}
        </Tooltip>
      ),
    },
    {
      title: "address",
      dataIndex: "address",
      key: "address",
      ellipsis: {
        showTitle: false,
      },
      width: "230px",
      render: (text, record, index) => (
        <Tooltip placement="topLeft" title={record?.address}>
          {record?.address}
        </Tooltip>
      ),
    }, 
    {
      title: "startus",
      dataIndex: "transactionshsh",
      key: "transactionshsh",
      ellipsis: {
        showTitle: false,
      },
      width: "140px",
      render: (text, record, index) => (
        <Tooltip placement="topLeft" title={record.Note}>
          <p
           
            style={{ width: 90,color:"#fff" }}
          >
            {record.active === 0
              ? "success"
              :"pending"}
          </p>
        </Tooltip>
      ),
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      width: "350px",
      ellipsis: {
        showTitle: false,
      },
      render: (address) => (
        <Tooltip placement="topLeft" title={new Date(address).toLocaleString()}>
          {new Date(address).toLocaleString()}
        </Tooltip>
      ),
    },
  ];
  const onTabChange = (page, pageSize) => {
    setpage(page);
    setpageSize(pageSize);
  };
  return (
    <>
      <Spin spinning={StackingbounsSlice && !StackingbounsSlice.isLoader}>
        <button className="btn btn-primary mx-3 mt-3" onClick={() => {

          navigation("/dashboard");
        }}>back to home</button>
        <div className="container-fluid ">
          <div className="row p-2">
            <div className="col-12 p-2 p-lg-2">
              <Table
                columns={columns}
                dataSource={Alldata}
                bordered={true}
                title={() => "Withdraw History"}
                scroll={{ x: "1500px " }}
                pagination={{
                  defaultPageSize: 10,
                  showSizeChanger: true,
                  pageSizeOptions: ["25", "50", "75", "100"],
                  showPageSizeOptions: true,
                  current: page,
                  onChange: (page, pageSize) => onTabChange(page, pageSize),
                }}
              />
            </div>
          </div>
        </div>
      </Spin>
    </>
  );
}

export default Withdrdatadata1;
