import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Navbaradmin from "../../components/Navbaradmin/Navbaradmin";
import { Stackingbouns } from "../../Redux/Stackingbouns";
import { Button, Modal, Select, Spin, Table, Tooltip } from "antd";
import { getTransferdata } from "../../Redux/TranfarSlice";
import {
  Adminprice,
  Adminuserdata,
  Alltranfordata,
  userdatablock,
  AdminsendAmount,
  Admintranfor,
  Withdrdatadata,
  Withdrdatadata12,
} from "../../Redux/admin";
import InputField from "../../components/InputField";
import { toast } from "react-toastify";
import Navbar1 from "../../components/Navbar/Navbar";
import axios from "axios";

function Adminwithdraw_details() {
  const StackingbounsSlice = useSelector((state) => state.Adminuserdata);
  const [page, setpage] = React.useState(1);
  const [pageSize, setpageSize] = React.useState(10);
  const [Alldata, setAlldata] = React.useState([]);
  const dispatch = useDispatch();
  const location = useLocation();
  const [values, setValues] = React.useState({
    username: "",
    Amount: "",
    Walletname: "Main Wallet",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };
  useEffect(() => {
    getalldata();
  }, []);
  const getalldata = async () => {
    const res = await dispatch(
      Withdrdatadata({
        Token:
          JSON.parse(localStorage.getItem("data")) &&
          JSON.parse(localStorage.getItem("data")).data.token,
      })
    );
    console.log("res.payload.data", res.payload.data.deposit);

    setAlldata(res.payload.data.deposit);
  };
  const handleSubmit = async (e) => {
    if (values.username !== "" || values.Amount > 0) {
      const res = await dispatch(
        Withdrdatadata({
          Token:
            JSON.parse(localStorage.getItem("data")) &&
            JSON.parse(localStorage.getItem("data")).data.token,
        })
      );
      if (res.payload.data.isSuccess) {
        toast.success(res.payload.data.message);
      } else {
        toast.error(res.payload.data.message);
      }
    }
  };
  const columns = [
    {
      title: "Sr No",
      dataIndex: "sno",
      key: "sno",
      width: "80px",
      render: (value, item, index) =>
        page === 1 ? index + 1 : (page - 1) * pageSize + (index + 1),
    },
    {
      title: "amount",
      dataIndex: "amount",
      key: "amount",
      ellipsis: {
        showTitle: false,
      },
      width: "230px",
      render: (text, record, index) => (
        <Tooltip placement="topLeft" title={record.amount}>
          {record.amount}
        </Tooltip>
      ),
    },
    {
      title: "address",
      dataIndex: "address",
      key: "address",
      ellipsis: {
        showTitle: false,
      },
      width: "230px",
      render: (text, record, index) => (
        <Tooltip placement="topLeft" title={record.address}>
          {record.address}
        </Tooltip>
      ),
    },
    {
      title: "startus",
      dataIndex: "active",
      key: "active",
      ellipsis: {
        showTitle: false,
      },
      width: "230px",
      render: (text, record, index) => (
        <Tooltip
          placement="topLeft"
          title={record.active !== 1 ? "received" : "pending"}
        >
          <button
            className={
              record.active !== 1 ? "btn bg-success" : "btn bg-warning"
            }
          >
            {" "}
            {record.active !== 1 ? "received" : "pending"}
          </button>
        </Tooltip>
      ),
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      width: "350px",
      ellipsis: {
        showTitle: false,
      },
      render: (address) => (
        <Tooltip placement="topLeft" title={new Date(address).toLocaleString()}>
          {new Date(address).toLocaleString()}
        </Tooltip>
      ),
    },
    {
      title: "update startus",
      dataIndex: "createdAt",
      key: "createdAt",
      width: "350px",
      ellipsis: {
        showTitle: false,
      },
      render: (text, record, index) => (
        <p
          className={
            record.transactionshsh === "no"
              ? "d-none"
              : record.transactionshsh === "yes"
              ? ""
              : ""
          }
        >
          <button
            className="btn btn-success mx-2"
            onClick={async () => {
              let headersList = await {
                Accept: "*/*",
                "User-Agent": "Thunder Client (https://www.thunderclient.com)",
                Authorization:
                  JSON.parse(localStorage.getItem("data")) &&
                  JSON.parse(localStorage.getItem("data")).data.token,
                "Content-Type": "application/json",
              };

              let bodyContent = JSON.stringify({
                active: 0,
              });

              let reqOptions = {
                url:
                  "https://infinityai-backend-production.up.railway.app/api/Withdraw/withdraw/" +
                  record._id,
                method: "PUT",
                headers: headersList,
                data: bodyContent,
              };

              let response = await axios.request(reqOptions);
              console.log(response.data);
              getalldata();
            }}
          >
            accept
          </button>
          <button
            className="btn btn-danger mx-2"
            onClick={async () => {
              let headersList = await {
                Accept: "*/*",
                "User-Agent": "Thunder Client (https://www.thunderclient.com)",
                Authorization:
                  JSON.parse(localStorage.getItem("data")) &&
                  JSON.parse(localStorage.getItem("data")).data.token,
                "Content-Type": "application/json",
              };

              let bodyContent = JSON.stringify({
                active: 1,
              });

              let reqOptions = {
                url:
                  "https://infinityai-backend-production.up.railway.app/api/Withdraw/withdraw/" +
                  record._id,
                method: "PUT",
                headers: headersList,
                data: bodyContent,
              };

              let response = await axios.request(reqOptions);
              console.log(response.data);
              getalldata();
            }}
          >
            reject{" "}
          </button>
        </p>
      ),
    },
  ];
  const onTabChange = (page, pageSize) => {
    setpage(page);
    setpageSize(pageSize);
  };
  return (
    <>
      <Spin spinning={StackingbounsSlice && !StackingbounsSlice.isLoader}>
        <Navbar1 />
        <div className="container-fluid blackbg">
          <div className="mainsection">
            <div className="row p-4">
              <div className="col-12 p-2 p-lg-3">
                <Table
                  columns={columns}
                  dataSource={Alldata}
                  bordered={true}
                  title={() => "Withdraw History"}
                  scroll={{ x: "1500px " }}
                  pagination={{
                    defaultPageSize: 10,
                    showSizeChanger: true,
                    pageSizeOptions: ["25", "50", "75", "100"],
                    showPageSizeOptions: true,
                    current: page,
                    onChange: (page, pageSize) => onTabChange(page, pageSize),
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </Spin>
    </>
  );
}

export default Adminwithdraw_details;
